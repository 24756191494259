import React from 'react';
import GeneratorImagini  from "./GeneratorImagini";
function App() {
  return (
    <div className="">
      <GeneratorImagini />
    </div>
  );
} 

export default App;