import React, { useState, useEffect } from 'react';
import * as fal from "@fal-ai/serverless-client";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Configurarea cheii API
fal.config({
  credentials: "12396a56-1300-42e5-be47-f7c1390f6f6a:06ab676cc0436d53828301f0c70aa52b"
});

const DimensiuniImagine = {
  "square_hd": "Pătrat HD",
  "square": "Pătrat",
  "portrait_4_3": "Portret 4:3",
  "portrait_16_9": "Portret 16:9",
  "landscape_4_3": "Peisaj 4:3",
  "landscape_16_9": "Peisaj 16:9"
};

const prompturiExemple = [
  "Realizează un logo fotorealist 8K pentru 'Aipro Vision', o companie specializată în dezvoltarea de software personalizat. Logo-ul ar trebui să transmită inovație, tehnologie și precizie. Designul trebuie să includă o tipografie elegantă și modernă, cu un font inspirat din tehnologie, integrând eventual modele subtile de circuite electronice sau elemente de cod binar în text. Încorporează un simbol futurist de ochi, reprezentând 'Vision', cu un efect digital sau pixelat subtil. Schema de culori ar trebui să fie profesională și de înaltă tehnologie, utilizând nuanțe de albastru, argintiu și negru pentru a evoca încredere și tehnologie de vârf. Logo-ul trebuie să aibă un fundal curat și minimalist pentru a asigura concentrarea asupra detaliilor și clarității designului.",
  "Imagine fotorealistă a unui birou modern, cu angajați colaborând la un proiect folosind tehnologie de ultimă generație.",
  "Colaj vizual care prezintă evoluția unei afaceri de succes, de la startup la o corporație globală.",
  "Ilustrație cu un antreprenor prezentând un pitch investitorilor într-o sală de conferințe elegantă.",
  "Portret al unui CEO în biroul său, cu vedere panoramică asupra orașului, simbolizând viziunea de afaceri.",
  "Grafică abstractă reprezentând creșterea financiară, cu grafice, diagrame și simboluri de succes economic.",
  "Postare fotorealistă 8K pentru Facebook care promovează o ofertă completă de digitalizare, incluzând echipamente, soluții software, cursuri și audit, cu un design modern și high-tech.",
  "Imagine fotorealistă a unui cupon promoțional elegant pentru un magazin online, evidențiind reduceri atractive și un design profesional."
];


const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("ImaginiGenerateDB", 1);
    request.onerror = (event) => reject("Eroare la deschiderea bazei de date");
    request.onsuccess = (event) => resolve(event.target.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("istoric", { keyPath: "id", autoIncrement: true });
    };
  });
};

const salveazaInIstoric = async (itemIstoric) => {
  const db = await openDB();
  const transaction = db.transaction(["istoric"], "readwrite");
  const store = transaction.objectStore("istoric");
  return new Promise((resolve, reject) => {
    const request = store.add(itemIstoric);
    request.onerror = (event) => reject("Eroare la salvarea în istoric");
    request.onsuccess = (event) => resolve(event.target.result);
  });
};

const incarcaIstoric = async () => {
  const db = await openDB();
  const transaction = db.transaction(["istoric"], "readonly");
  const store = transaction.objectStore("istoric");
  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onerror = (event) => reject("Eroare la încărcarea istoricului");
    request.onsuccess = (event) => resolve(event.target.result);
  });
};

const GeneratorImagini = () => {
  const [prompt, setPrompt] = useState('Imagine cu un studio de radio modern, plin de echipamente de ultimă generație și prezentatori entuziaști.');
  const [imaginiGenerate, setImaginiGenerate] = useState([]);
  const [seIncarca, setSeIncarca] = useState(false);
  const [eroare, setEroare] = useState('');
  const [istoric, setIstoric] = useState([]);
  const [setari, setSetari] = useState({
    image_size: "landscape_4_3",
    num_inference_steps: 28,
    guidance_scale: 3.5,
    num_images: 1,
    enable_safety_checker: true
  });

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {});
    incarcaIstoric().then(istoricSalvat => {
      setIstoric(istoricSalvat.reverse());
    }).catch(error => {
      console.error("Eroare la încărcarea istoricului:", error);
    });
    return () => {
      Fancybox.destroy();
    };
  }, []);

  const handleSchimbareSetare = (setare, valoare) => {
    setSetari(prev => ({ ...prev, [setare]: valoare }));
  };

  const genereazaImagine = async () => {
    setSeIncarca(true);
    setEroare('');
    setImaginiGenerate([]);

    try {
      const rezultat = await fal.subscribe("fal-ai/flux", {
        input: {
          prompt: prompt,
          ...setari
        }
      });

      if (rezultat.images && rezultat.images.length > 0) {
        const imaginiNoi = rezultat.images.map(img => img.url);
        setImaginiGenerate(imaginiNoi);
        
        const itemIstoric = { prompt, imagini: imaginiNoi, timestamp: new Date().toLocaleString() };
        await salveazaInIstoric(itemIstoric);
        setIstoric(prev => [itemIstoric, ...prev]);
      } else {
        throw new Error("Nu s-a generat nicio imagine");
      }
    } catch (eroare) {
      console.error("Eroare la generarea imaginii:", eroare);
      setEroare("A apărut o eroare la generarea imaginii. Vă rugăm să verificați setările și să încercați din nou.");
    }

    setSeIncarca(false);
  };

  const stilContainer = {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '24px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#1a202c',
    color: 'white',
    minHeight: '100vh'
  };

  const stilGrid = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '24px'
  };

  const stilCard = {
    backgroundColor: '#2d3748',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '24px'
  };

  const stilInput = {
    width: '100%',
    padding: '8px',
    backgroundColor: '#4a5568',
    border: '1px solid #718096',
    borderRadius: '4px',
    color: 'white',
    marginBottom: '16px'
  };

  const stilButon = {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4299e1',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '16px'
  };

  const stilSlider = {
    width: '100%',
    marginBottom: '16px'
  };

  return (
    <div style={stilContainer}>
      <h1 style={{fontSize: '2rem', fontWeight: 'bold', marginBottom: '24px', textAlign: 'center'}}>Generator de Imagini Fal-AI pentru Radio</h1>
      <div style={stilGrid}>
        <div style={stilCard}>
          <h2 style={{fontSize: '1.5rem', fontWeight: 'semibold', marginBottom: '16px'}}>Date de Intrare</h2>
          <div>
            <label style={{display: 'block', marginBottom: '8px'}}>Prompt</label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Introduceți promptul dvs."
              style={{...stilInput, height: '100px'}}
            />
          </div>
          <div style={{marginBottom: '16px'}}>
            <h3 style={{fontSize: '1rem', fontWeight: 'semibold', marginBottom: '8px'}}>Exemple de prompturi:</h3>
            <ul style={{listStyleType: 'none', padding: 0}}>
              {prompturiExemple.map((exemplu, index) => (
                <li key={index} style={{marginBottom: '8px'}}>
                  <button 
                    onClick={() => setPrompt(exemplu)}
                    style={{...stilButon, padding: '4px 8px', fontSize: '0.9rem'}}
                  >
                    {exemplu}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <label style={{display: 'block', marginBottom: '8px'}}>Dimensiune Imagine</label>
            <select
              value={setari.image_size}
              onChange={(e) => handleSchimbareSetare('image_size', e.target.value)}
              style={stilInput}
            >
              {Object.entries(DimensiuniImagine).map(([value, label]) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </select>
          </div>
          <div>
            <label style={{display: 'block', marginBottom: '8px'}}>
              Pași de Inferență: {setari.num_inference_steps}
            </label>
            <input
              type="range"
              min="1"
              max="50"
              value={setari.num_inference_steps}
              onChange={(e) => handleSchimbareSetare('num_inference_steps', parseInt(e.target.value))}
              style={stilSlider}
            />
          </div>
          <div>
            <label style={{display: 'block', marginBottom: '8px'}}>
              Scală de Ghidare (CFG): {setari.guidance_scale.toFixed(1)}
            </label>
            <input
              type="range"
              min="1"
              max="20"
              step="0.1"
              value={setari.guidance_scale}
              onChange={(e) => handleSchimbareSetare('guidance_scale', parseFloat(e.target.value))}
              style={stilSlider}
            />
          </div>
          <div>
            <label style={{display: 'block', marginBottom: '8px'}}>
              Număr de Imagini: {setari.num_images}
            </label>
            <input
              type="range"
              min="1"
              max="4"
              value={setari.num_images}
              onChange={(e) => handleSchimbareSetare('num_images', parseInt(e.target.value))}
              style={stilSlider}
            />
          </div>
          <div>
            <label style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
              <input
                type="checkbox"
                checked={setari.enable_safety_checker}
                onChange={(e) => handleSchimbareSetare('enable_safety_checker', e.target.checked)}
                style={{marginRight: '8px'}}
              />
              Activează Verificarea de Siguranță
            </label>
          </div>
          <button
            onClick={genereazaImagine}
            disabled={seIncarca}
            style={{...stilButon, opacity: seIncarca ? 0.5 : 1}}
          >
            {seIncarca ? 'Se generează...' : 'Generează Imagine'}
          </button>
          {eroare && <p style={{color: 'red', marginTop: '16px'}}>{eroare}</p>}
        </div>
        <div style={stilCard}>
          <h2 style={{fontSize: '1.5rem', fontWeight: 'semibold', marginBottom: '16px'}}>Rezultat Curent</h2>
          {imaginiGenerate.length > 0 ? (
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '16px'}}>
              {imaginiGenerate.map((url, index) => (
                <div key={index}>
                  <a href={url} data-fancybox="gallery-curent">
                    <img src={url} alt={`Generată ${index + 1}`} style={{width: '100%', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}} />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <div style={{height: '256px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#a0aec0'}}>
              Așteptăm datele dvs. de intrare...
            </div>
          )}
        </div>
      </div>
      <div style={stilCard}>
        <h2 style={{fontSize: '1.5rem', fontWeight: 'semibold', marginBottom: '16px'}}>Istoric Generări</h2>
        {istoric.length > 0 ? (
          <div>
            {istoric.map((item, index) => (
              <div key={index} style={{marginBottom: '24px', borderBottom: '1px solid #4a5568', paddingBottom: '16px'}}>
                <p style={{marginBottom: '8px'}}><strong>Prompt:</strong> {item.prompt}</p>
                <p style={{marginBottom: '8px'}}><strong>Data generării:</strong> {item.timestamp}</p>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', gap: '8px'}}>
                  {item.imagini.map((url, imgIndex) => (
                    <a key={imgIndex} href={url} data-fancybox={`gallery-${index}`}>
                      <img src={url} alt={`Istorică ${index + 1}-${imgIndex + 1}`} style={{width: '100%', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}} />
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Nu există încă generări în istoric.</p>
        )}
      </div>
    </div>
  );
};

export default GeneratorImagini;